import React from 'react';
import { withProviders } from '../../../contexts/main/withProviders';
import { withChallengeData } from '../../../contexts/storage-contexts/Challenge';
import { withLocation } from '../../../contexts/Location/withLocation';
import { IThankYouPageProps } from './interfaces';
import {
  FCWithChildren,
  serverTimelineTypeToClientTimelineString,
} from '@wix/challenges-web-library';
import { useLocation } from '../../../contexts/Location/LocationContext';

import get from 'lodash/get';

import { Button, ButtonPriority } from 'wix-ui-tpa/cssVars';
import { ButtonNames } from '../../../contexts/main/biInterfaces';
import { applyProviders } from '../../../services/applyProviders';
import { withUser } from '../../../contexts/User/withUser';
import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';
import { State as ParticipantState } from '@wix/ambassador-challenges-v1-participant/types';
import { setElementToViewport } from '../../../services/scrollHelpers';
import { useEnvironment, useTranslation, useBi } from '@wix/yoshi-flow-editor';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';
import { ProgramAccessType } from '../../../types/programs';
import { classes, st } from './Widget.st.css';
import { useGeneralData } from '../../../contexts/GeneralDataProvider/GeneralDataContext';
import { getProgramSlug } from '../../../selectors/getProgramSlug';

const ThankYouPage: FCWithChildren<IThankYouPageProps> = (props) => {
  const pageName = 'thank-you-page';
  const { t } = useTranslation();
  const bi = useBi();
  const { query, baseUrl } = useLocation();
  const { showOneAppInfo = false } = query || {};

  const [isGoToChallengeClicked, updateIsGoToChallengeClicked] =
    React.useState(false);
  const { isMobile, language } = useEnvironment();
  const { msid } = useGeneralData();

  const wrapperRef = React.useRef();
  React.useEffect(() => {
    const wrapperDOM = wrapperRef?.current;

    setElementToViewport(wrapperDOM);
  }, []);

  React.useEffect(() => {
    const participantState = props?.participant?.transitions?.[0].state;
    if (
      isGoToChallengeClicked &&
      participantState !== ParticipantState.PAYMENT_STARTED
    ) {
      goToChallengePage(challenge);
    } else if (participantState === ParticipantState.PAYMENT_STARTED) {
      setTimeout(() => {
        props.updateParticipant();
      }, 200);
    }
  }, [isGoToChallengeClicked, props.participant]);

  const goToChallengePage = async (_challenge: Challenge) => {
    await bi.report(
      memberWebAppButtonClickV2({
        buttonName: ButtonNames.BackToChallenge,
      }),
    );

    props.navigateToProgramPage({
      challengeId: getProgramSlug(_challenge),
      isParticipant: true,
    });
  };

  const {
    challengeData: { challenge },
  } = props;
  const isJoined = props.userType !== ParticipantState.JOIN_REQUESTED;

  const durationString = serverTimelineTypeToClientTimelineString(
    challenge?.settings?.timelineType as any,
    language,
    t,
    'challenge-card.duration-string.ongoing',
    `challenge-card.duration-string.flexible.days_icu`,
    `challenge-card.duration-string.flexible.weeks_icu`,
    'challenge-card.duration-string.no-limit',
  );
  const inviteLink = `${baseUrl}/_serverless/challenges-web-serverless/redirect-invite-link?programId=${challenge.id}&msid=${msid}`;

  return (
    <main
      data-hook={pageName}
      className={st(classes.root, {
        mobile: isMobile,
      })}
    >
      <div className={classes.wrapper} ref={wrapperRef}>
        <h1 className={classes.title} data-hook={`${pageName}-title`}>
          {isJoined
            ? t(`thank-you.page.title`)
            : t(`thank-you.page.secret.title`)}
        </h1>
        {challenge.settings?.accessRestrictions?.accessType ===
          ProgramAccessType.PRIVATE && (
          <p
            className={classes.description}
            data-hook={`${pageName}-description`}
          >
            {isJoined
              ? t(`thank-you.page.description`)
              : t(`thank-you.page.secret.description`)}
          </p>
        )}

        <div className={classes.contentBox}>
          <h2
            className={classes.contentTitle}
            data-hook={`${pageName}-content-title`}
          >
            {challenge.settings?.description.title}
          </h2>

          <p className={classes.text} data-hook={`${pageName}-text-summary`}>
            {durationString} ·{' '}
            {t(`thank-you.page.info.steps_icu`, {
              count: get(challenge, 'stepsSummary.stepsNumber', 0),
            })}
          </p>

          {isJoined && showOneAppInfo === 'true' ? (
            <p
              className={classes.text}
              data-hook={`${pageName}-text-details-link`}
            >
              <a
                href={inviteLink}
                target="_blank"
                className={classes.link}
                rel="noreferrer"
              >
                {t('thank-you.page.view-in-app')}
              </a>
            </p>
          ) : (
            ''
          )}
          <Button
            priority={ButtonPriority.primary}
            disabled={isGoToChallengeClicked}
            className={classes.ctaButton}
            data-hook={`${pageName}-cta`}
            fullWidth={false}
            onClick={async (e) => {
              updateIsGoToChallengeClicked(true);
              e && e.preventDefault();
            }}
          >
            {t(isJoined ? 'thank-you.page.back' : 'thank-you.page.return.cta')}
          </Button>
        </div>
      </div>
    </main>
  );
};

ThankYouPage.displayName = 'thank-you-page';

export const ThankYouPageWithProviders: FCWithChildren<any> = applyProviders(
  ThankYouPage,
  [withLocation, withChallengeData, withUser],
);

export default applyProviders(ThankYouPageWithProviders, [
  withProviders,
]) as FCWithChildren;
